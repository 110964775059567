/* tslint:disable */
/* eslint-disable */
/**
 * MRVPP PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApproveRequestDto } from '../models';
// @ts-ignore
import { AreaOfExpertise } from '../models';
// @ts-ignore
import { CertificateNumberAvailabilityResult } from '../models';
// @ts-ignore
import { CheckCertificateNumberAvailabilityQuery } from '../models';
// @ts-ignore
import { ListRequestDtoPaginatedList } from '../models';
// @ts-ignore
import { RequestCompanyType } from '../models';
// @ts-ignore
import { RequestDetailsDto } from '../models';
// @ts-ignore
import { RequestStatus } from '../models';
// @ts-ignore
import { RequestType } from '../models';
/**
 * RequestsApi - axios parameter creator
 * @export
 */
export const RequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CheckCertificateNumberAvailabilityQuery} [checkCertificateNumberAvailabilityQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        /**
         * 
         * @param {string} [currentUserId] 
         * @param {boolean} [isLoggedInAsCompany] 
         * @param {string} [companyId] 
         * @param {boolean} [isFromPublic] 
         * @param {string} [idNumber] 
         * @param {string} [companyName] 
         * @param {string} [companyEmailAddress] 
         * @param {string} [contactPersonPhone] 
         * @param {string} [websiteUrl] 
         * @param {string} [taxNumber] 
         * @param {string} [responsiblePersonFullName] 
         * @param {string} [responsiblePersonFunction] 
         * @param {string} [contactPersonFirstName] 
         * @param {string} [contactPersonLastName] 
         * @param {string} [contactPersonEmail] 
         * @param {boolean} [isMegaPermission] 
         * @param {boolean} [isSupplyLicense] 
         * @param {boolean} [isBalancingContract] 
         * @param {boolean} [isMarketAgreement] 
         * @param {boolean} [isIntETCCode] 
         * @param {boolean} [isInformationTrue] 
         * @param {string} [address] 
         * @param {string} [place] 
         * @param {string} [postCode] 
         * @param {string} [comments] 
         * @param {string} [municipalityId] 
         * @param {string} [stateEntityId] 
         * @param {string} [licenseId] 
         * @param {string} [licenseDuration] 
         * @param {Array<string>} [certificationNumbers] 
         * @param {number} [totalNumberOfServiceTechnians] 
         * @param {boolean} [meetsEquipmentRegulations] 
         * @param {string} [languageId] 
         * @param {RequestType} [type] 
         * @param {RequestStatus} [status] 
         * @param {RequestCompanyType} [companyType] 
         * @param {AreaOfExpertise} [areaOfExpertise] 
         * @param {string} [businessActivityId] 
         * @param {Array<File>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsPost: async (currentUserId?: string, isLoggedInAsCompany?: boolean, companyId?: string, isFromPublic?: boolean, idNumber?: string, companyName?: string, companyEmailAddress?: string, contactPersonPhone?: string, websiteUrl?: string, taxNumber?: string, responsiblePersonFullName?: string, responsiblePersonFunction?: string, contactPersonFirstName?: string, contactPersonLastName?: string, contactPersonEmail?: string, isMegaPermission?: boolean, isSupplyLicense?: boolean, isBalancingContract?: boolean, isMarketAgreement?: boolean, isIntETCCode?: boolean, isInformationTrue?: boolean, address?: string, place?: string, postCode?: string, comments?: string, municipalityId?: string, stateEntityId?: string, licenseId?: string, licenseDuration?: string, certificationNumbers?: Array<string>, totalNumberOfServiceTechnians?: number, meetsEquipmentRegulations?: boolean, languageId?: string, type?: RequestType, status?: RequestStatus, companyType?: RequestCompanyType, areaOfExpertise?: AreaOfExpertise, businessActivityId?: string, attachments?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // const localVarPath = `/api/Requests`;
            const localVarPath = `/api/Public/requests/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (currentUserId !== undefined) {
                localVarFormParams.append('CurrentUserId', currentUserId as any);
            }

            if (isLoggedInAsCompany !== undefined) {
                localVarFormParams.append('IsLoggedInAsCompany', isLoggedInAsCompany as any);
            }

            if (companyId !== undefined) {
                localVarFormParams.append('CompanyId', companyId as any);
            }

            if (isFromPublic !== undefined) {
                localVarFormParams.append('IsFromPublic', isFromPublic as any);
            }

            if (idNumber !== undefined) {
                localVarFormParams.append('IdNumber', idNumber as any);
            }

            if (companyName !== undefined) {
                localVarFormParams.append('CompanyName', companyName as any);
            }

            if (companyEmailAddress !== undefined) {
                localVarFormParams.append('CompanyEmailAddress', companyEmailAddress as any);
            }

            if (contactPersonPhone !== undefined) {
                localVarFormParams.append('ContactPersonPhone', contactPersonPhone as any);
            }

            if (websiteUrl !== undefined) {
                localVarFormParams.append('WebsiteUrl', websiteUrl as any);
            }

            if (taxNumber !== undefined) {
                localVarFormParams.append('TaxNumber', taxNumber as any);
            }

            if (responsiblePersonFullName !== undefined) {
                localVarFormParams.append('ResponsiblePersonFullName', responsiblePersonFullName as any);
            }

            if (responsiblePersonFunction !== undefined) {
                localVarFormParams.append('ResponsiblePersonFunction', responsiblePersonFunction as any);
            }

            if (contactPersonFirstName !== undefined) {
                localVarFormParams.append('ContactPersonFirstName', contactPersonFirstName as any);
            }

            if (contactPersonLastName !== undefined) {
                localVarFormParams.append('ContactPersonLastName', contactPersonLastName as any);
            }

            if (contactPersonEmail !== undefined) {
                localVarFormParams.append('ContactPersonEmail', contactPersonEmail as any);
            }

            if (isMegaPermission !== undefined) {
                localVarFormParams.append('IsMegaPermission', isMegaPermission as any);
            }

            if (isSupplyLicense !== undefined) {
                localVarFormParams.append('IsSupplyLicense', isSupplyLicense as any);
            }

            if (isBalancingContract !== undefined) {
                localVarFormParams.append('IsBalancingContract', isBalancingContract as any);
            }

            if (isMarketAgreement !== undefined) {
                localVarFormParams.append('IsMarketAgreement', isMarketAgreement as any);
            }

            if (isIntETCCode !== undefined) {
                localVarFormParams.append('IsIntETCCode', isIntETCCode as any);
            }

            if (isInformationTrue !== undefined) {
                localVarFormParams.append('IsInformationTrue', isInformationTrue as any);
            }

            if (address !== undefined) {
                localVarFormParams.append('Address', address as any);
            }

            if (place !== undefined) {
                localVarFormParams.append('Place', place as any);
            }

            if (postCode !== undefined) {
                localVarFormParams.append('PostCode', postCode as any);
            }

            if (comments !== undefined) {
                localVarFormParams.append('Comments', comments as any);
            }

            if (municipalityId !== undefined) {
                localVarFormParams.append('MunicipalityId', municipalityId as any);
            }

            if (stateEntityId !== undefined) {
                localVarFormParams.append('StateEntityId', stateEntityId as any);
            }

            if (licenseId !== undefined) {
                localVarFormParams.append('LicenseId', licenseId as any);
            }

            if (licenseDuration !== undefined) {
                localVarFormParams.append('LicenseDuration', licenseDuration as any);
            }
            if (certificationNumbers) {
                certificationNumbers.forEach((element) => {
                    localVarFormParams.append('CertificationNumbers', element as any);
                })
            }


            if (totalNumberOfServiceTechnians !== undefined) {
                localVarFormParams.append('TotalNumberOfServiceTechnians', totalNumberOfServiceTechnians as any);
            }

            if (meetsEquipmentRegulations !== undefined) {
                localVarFormParams.append('MeetsEquipmentRegulations', meetsEquipmentRegulations as any);
            }

            if (languageId !== undefined) {
                localVarFormParams.append('LanguageId', languageId as any);
            }

            if (type !== undefined) {
                localVarFormParams.append('Type', type as any);
            }

            if (status !== undefined) {
                localVarFormParams.append('Status', status as any);
            }

            if (companyType !== undefined) {
                localVarFormParams.append('CompanyType', companyType as any);
            }

            if (areaOfExpertise !== undefined) {
                localVarFormParams.append('AreaOfExpertise', areaOfExpertise as any);
            }

            if (businessActivityId !== undefined) {
                localVarFormParams.append('BusinessActivityId', businessActivityId as any);
            }
            if (attachments) {
                attachments.forEach((element) => {
                    localVarFormParams.append('Attachments', element as any);
                })
            }



            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequestsApi - functional programming interface
 * @export
 */
export const RequestsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [currentUserId] 
         * @param {boolean} [isLoggedInAsCompany] 
         * @param {string} [companyId] 
         * @param {boolean} [isFromPublic] 
         * @param {string} [idNumber] 
         * @param {string} [companyName] 
         * @param {string} [companyEmailAddress] 
         * @param {string} [contactPersonPhone] 
         * @param {string} [websiteUrl] 
         * @param {string} [taxNumber] 
         * @param {string} [responsiblePersonFullName] 
         * @param {string} [responsiblePersonFunction] 
         * @param {string} [contactPersonFirstName] 
         * @param {string} [contactPersonLastName] 
         * @param {string} [contactPersonEmail] 
         * @param {boolean} [isMegaPermission] 
         * @param {boolean} [isSupplyLicense] 
         * @param {boolean} [isBalancingContract] 
         * @param {boolean} [isMarketAgreement] 
         * @param {boolean} [isIntETCCode] 
         * @param {boolean} [isInformationTrue] 
         * @param {string} [address] 
         * @param {string} [place] 
         * @param {string} [postCode] 
         * @param {string} [comments] 
         * @param {string} [municipalityId] 
         * @param {string} [stateEntityId] 
         * @param {string} [licenseId] 
         * @param {string} [licenseDuration] 
         * @param {Array<string>} [certificationNumbers] 
         * @param {number} [totalNumberOfServiceTechnians] 
         * @param {boolean} [meetsEquipmentRegulations] 
         * @param {string} [languageId] 
         * @param {RequestType} [type] 
         * @param {RequestStatus} [status] 
         * @param {RequestCompanyType} [companyType] 
         * @param {AreaOfExpertise} [areaOfExpertise] 
         * @param {string} [businessActivityId] 
         * @param {Array<File>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRequestsPost(currentUserId?: string, isLoggedInAsCompany?: boolean, companyId?: string, isFromPublic?: boolean, idNumber?: string, companyName?: string, companyEmailAddress?: string, contactPersonPhone?: string, websiteUrl?: string, taxNumber?: string, responsiblePersonFullName?: string, responsiblePersonFunction?: string, contactPersonFirstName?: string, contactPersonLastName?: string, contactPersonEmail?: string, isMegaPermission?: boolean, isSupplyLicense?: boolean, isBalancingContract?: boolean, isMarketAgreement?: boolean, isIntETCCode?: boolean, isInformationTrue?: boolean, address?: string, place?: string, postCode?: string, comments?: string, municipalityId?: string, stateEntityId?: string, licenseId?: string, licenseDuration?: string, certificationNumbers?: Array<string>, totalNumberOfServiceTechnians?: number, meetsEquipmentRegulations?: boolean, languageId?: string, type?: RequestType, status?: RequestStatus, companyType?: RequestCompanyType, areaOfExpertise?: AreaOfExpertise, businessActivityId?: string, attachments?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRequestsPost(currentUserId, isLoggedInAsCompany, companyId, isFromPublic, idNumber, companyName, companyEmailAddress, contactPersonPhone, websiteUrl, taxNumber, responsiblePersonFullName, responsiblePersonFunction, contactPersonFirstName, contactPersonLastName, contactPersonEmail, isMegaPermission, isSupplyLicense, isBalancingContract, isMarketAgreement, isIntETCCode, isInformationTrue, address, place, postCode, comments, municipalityId, stateEntityId, licenseId, licenseDuration, certificationNumbers, totalNumberOfServiceTechnians, meetsEquipmentRegulations, languageId, type, status, companyType, areaOfExpertise, businessActivityId, attachments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RequestsApi - factory interface
 * @export
 */
export const RequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequestsApiFp(configuration)
    return {
        /**
         * 
         * @param {CheckCertificateNumberAvailabilityQuery} [checkCertificateNumberAvailabilityQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */

        /**
         * 
         * @param {string} [currentUserId] 
         * @param {boolean} [isLoggedInAsCompany] 
         * @param {string} [companyId] 
         * @param {boolean} [isFromPublic] 
         * @param {string} [idNumber] 
         * @param {string} [companyName] 
         * @param {string} [companyEmailAddress] 
         * @param {string} [contactPersonPhone] 
         * @param {string} [websiteUrl] 
         * @param {string} [taxNumber] 
         * @param {string} [responsiblePersonFullName] 
         * @param {string} [responsiblePersonFunction] 
         * @param {string} [contactPersonFirstName] 
         * @param {string} [contactPersonLastName] 
         * @param {string} [contactPersonEmail] 
         * @param {boolean} [isMegaPermission] 
         * @param {boolean} [isSupplyLicense] 
         * @param {boolean} [isBalancingContract] 
         * @param {boolean} [isMarketAgreement] 
         * @param {boolean} [isIntETCCode] 
         * @param {boolean} [isInformationTrue] 
         * @param {string} [address] 
         * @param {string} [place] 
         * @param {string} [postCode] 
         * @param {string} [comments] 
         * @param {string} [municipalityId] 
         * @param {string} [stateEntityId] 
         * @param {string} [licenseId] 
         * @param {string} [licenseDuration] 
         * @param {Array<string>} [certificationNumbers] 
         * @param {number} [totalNumberOfServiceTechnians] 
         * @param {boolean} [meetsEquipmentRegulations] 
         * @param {string} [languageId] 
         * @param {RequestType} [type] 
         * @param {RequestStatus} [status] 
         * @param {RequestCompanyType} [companyType] 
         * @param {AreaOfExpertise} [areaOfExpertise] 
         * @param {string} [businessActivityId] 
         * @param {Array<File>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRequestsPost(currentUserId?: string, isLoggedInAsCompany?: boolean, companyId?: string, isFromPublic?: boolean, idNumber?: string, companyName?: string, companyEmailAddress?: string, contactPersonPhone?: string, websiteUrl?: string, taxNumber?: string, responsiblePersonFullName?: string, responsiblePersonFunction?: string, contactPersonFirstName?: string, contactPersonLastName?: string, contactPersonEmail?: string, isMegaPermission?: boolean, isSupplyLicense?: boolean, isBalancingContract?: boolean, isMarketAgreement?: boolean, isIntETCCode?: boolean, isInformationTrue?: boolean, address?: string, place?: string, postCode?: string, comments?: string, municipalityId?: string, stateEntityId?: string, licenseId?: string, licenseDuration?: string, certificationNumbers?: Array<string>, totalNumberOfServiceTechnians?: number, meetsEquipmentRegulations?: boolean, languageId?: string, type?: RequestType, status?: RequestStatus, companyType?: RequestCompanyType, areaOfExpertise?: AreaOfExpertise, businessActivityId?: string, attachments?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.apiRequestsPost(currentUserId, isLoggedInAsCompany, companyId, isFromPublic, idNumber, companyName, companyEmailAddress, contactPersonPhone, websiteUrl, taxNumber, responsiblePersonFullName, responsiblePersonFunction, contactPersonFirstName, contactPersonLastName, contactPersonEmail, isMegaPermission, isSupplyLicense, isBalancingContract, isMarketAgreement, isIntETCCode, isInformationTrue, address, place, postCode, comments, municipalityId, stateEntityId, licenseId, licenseDuration, certificationNumbers, totalNumberOfServiceTechnians, meetsEquipmentRegulations, languageId, type, status, companyType, areaOfExpertise, businessActivityId, attachments, options).then((request) => request(axios, basePath));
        },
    };
};


/**
 * Request parameters for apiRequestsPost operation in RequestsApi.
 * @export
 * @interface RequestsApiApiRequestsPostRequest
 */
export interface RequestsApiApiRequestsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly currentUserId?: string

    /**
     * 
     * @type {boolean}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly isLoggedInAsCompany?: boolean

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly companyId?: string

    /**
     * 
     * @type {boolean}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly isFromPublic?: boolean

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly idNumber?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly companyName?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly companyEmailAddress?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly contactPersonPhone?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly websiteUrl?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly taxNumber?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly responsiblePersonFullName?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly responsiblePersonFunction?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly contactPersonFirstName?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly contactPersonLastName?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly contactPersonEmail?: string

    /**
     * 
     * @type {boolean}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly isMegaPermission?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly isSupplyLicense?: boolean

    /**
    * 
    * @type {boolean}
    * @memberof RequestsApiApiRequestsPost
    */
    readonly isBalancingContract?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly isMarketAgreement?: boolean

    /**
    * 
    * @type {boolean}
    * @memberof RequestsApiApiRequestsPost
    */
    readonly isIntETCCode?: boolean

    /**
    * 
    * @type {boolean}
    * @memberof RequestsApiApiRequestsPost
    */
    readonly isInformationTrue?: boolean

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly address?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly place?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly postCode?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly comments?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly municipalityId?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly stateEntityId?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly licenseId?: string

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly licenseDuration?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly certificationNumbers?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly totalNumberOfServiceTechnians?: number

    /**
     * 
     * @type {boolean}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly meetsEquipmentRegulations?: boolean

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly languageId?: string

    /**
     * 
     * @type {RequestType}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly type?: RequestType

    /**
     * 
     * @type {RequestStatus}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly status?: RequestStatus

    /**
     * 
     * @type {RequestCompanyType}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly companyType?: RequestCompanyType

    /**
     * 
     * @type {AreaOfExpertise}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly areaOfExpertise?: AreaOfExpertise

    /**
     * 
     * @type {string}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly businessActivityId?: string

    /**
     * 
     * @type {Array<File>}
     * @memberof RequestsApiApiRequestsPost
     */
    readonly attachments?: Array<File>
}

/**
 * RequestsApi - object-oriented interface
 * @export
 * @class RequestsApi
 * @extends {BaseAPI}
 */
export class RequestsApi extends BaseAPI {
    /**
     * 
     * @param {RequestsApiApiRequestsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public apiRequestsPost(requestParameters: RequestsApiApiRequestsPostRequest = {}, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).apiRequestsPost(requestParameters.currentUserId, requestParameters.isLoggedInAsCompany, requestParameters.companyId, requestParameters.isFromPublic, requestParameters.idNumber, requestParameters.companyName, requestParameters.companyEmailAddress, requestParameters.contactPersonPhone, requestParameters.websiteUrl, requestParameters.taxNumber, requestParameters.responsiblePersonFullName, requestParameters.responsiblePersonFunction, requestParameters.contactPersonFirstName, requestParameters.contactPersonLastName, requestParameters.contactPersonEmail, requestParameters.isMegaPermission, requestParameters.isSupplyLicense, requestParameters.isBalancingContract, requestParameters.isMarketAgreement, requestParameters.isIntETCCode, requestParameters.isInformationTrue, requestParameters.address, requestParameters.place, requestParameters.postCode, requestParameters.comments, requestParameters.municipalityId, requestParameters.stateEntityId, requestParameters.licenseId, requestParameters.licenseDuration, requestParameters.certificationNumbers, requestParameters.totalNumberOfServiceTechnians, requestParameters.meetsEquipmentRegulations, requestParameters.languageId, requestParameters.type, requestParameters.status, requestParameters.companyType, requestParameters.areaOfExpertise, requestParameters.businessActivityId, requestParameters.attachments, options).then((request) => request(this.axios, this.basePath));
    }
}
