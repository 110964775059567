import React from "react";
import { Menu, Dropdown } from "antd";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CustomContextMenu = ({ isRequestPage }: { isRequestPage: boolean }) => {
  const { i18n, t } = useTranslation();

  const contextMenu = (
    <Menu>
      <Menu.Item key="1">
        <NavLink tag={Link} to="/requests/create/1">
          {t("request:producers", "Request Of EE Producers")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink tag={Link} to="/requests/create/2">
          {t(
            "request:consumers",
            "Request Of EE Consumers"
          )}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink tag={Link} to="/requests/create/3">
          {t(
            "request:suppliers",
            "Request Of EE Suppliers"
          )}
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  const languageMenu = (
    <Menu>
      <Menu.Item
        key={1}
        onClick={() =>
          handleLanguage("mk", "64924d45-7f38-11ee-85aa-0242ac110004")
        }
      >
        Macedonian
      </Menu.Item>
      {/* <Menu.Item
        key={2}
        onClick={() =>
          handleLanguage("hr", "64925194-7f38-11ee-85aa-0242ac110004")
        }
      >
        Hrvatski
      </Menu.Item>
      <Menu.Item
        key={3}
        onClick={() =>
          handleLanguage("sr", "64925080-7f38-11ee-85aa-0242ac110004")
        }
      >
        Srpski
      </Menu.Item> */}
      <Menu.Item
        key={4}
        onClick={() =>
          handleLanguage("en", "649251f1-7f38-11ee-85aa-0242ac110004")
        }
      >
        English
      </Menu.Item>
    </Menu>
  );

  const handleLanguage = (lng: string, id: string) => {
    localStorage.setItem("i18nextLng", lng);
    localStorage.setItem("i18nextLanguageId", id);
    i18n.changeLanguage(lng);
  };

  return (
    <div
      className="flex md:flex-row flex-col w-full bg-red md:justify-end md:items-center"
      style={{ width: "100%" }}
    >
      <Dropdown overlay={languageMenu} trigger={["click"]}>
        <div
          className={`${isRequestPage
            ? "text-dark hover:bg-slate-50"
            : "text-light hover:bg-slate-700"
            } flex flex-row items-center cursor-pointer text-xl md:text-base px-3 py-2 rounded-md`}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-globe "
          >
            <g>
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="2" y1="12" x2="22" y2="12"></line>
              <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
            </g>
          </svg>

          <span className="pl-2 text-xl md:text-base">
            {localStorage.getItem("i18nextLng")?.toUpperCase() || "MK"}
          </span>
        </div>
      </Dropdown>
      <Dropdown overlay={contextMenu} trigger={["click"]}>
        <div className=" text-light hover:bg-red-800 flex flex-row items-center cursor-pointer bg-red-600 text-xl md:text-base rounded-md pr-3">
          <span className="bg-red-900 p-2 rounded-bl-md rounded-tl-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </span>
          <span className="pr-2 pl-2 text-xl md:text-base">
            {t("menu:request-tab", "Request")}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            style={{ width: 16, height: 16 }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </Dropdown>
    </div>
  );
};

export default CustomContextMenu;
