import React from 'react';
import './registerListItem.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const OperatorItem = ({ registerId, companyName, companyType, taxNumber, t }) => (
    <div className='operator-item'>
        <div className="column">
            <h3 className='text-lg'>{t('register-list:company', 'Company:')} <strong> {companyName}</strong></h3>
            {companyType ? (<div className='address'>
                <svg style={{ width: 24, height: 24 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                </svg>
                <p>{t('register-list:company-type', 'Company Type: ')} <strong>{companyType}</strong></p>
            </div>) : null}

            {taxNumber ? (
                <div className='address'>
                    <svg style={{ width: 24, height: 24 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>

                    <p>{t('global:tax-number', 'Tax Number:')}  <strong>{taxNumber}</strong></p>
                </div>
            ) : null}

        </div>
        <div className='action'>
            <Link to={`/register-details/${registerId}`}>
                <button>{t('global:details', 'Details')}</button>
            </Link>

            {/* 
            <button>Uređaji</button> */}
        </div>
    </div>
);

const RegisterListItem = ({ searchResults }) =>
{
    const { t } = useTranslation();

    return (
        <div className='register-list-container mt-28 md:mt-12'>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <span className='operators'>{t('register-list:number-of-results', '{{dynamicValue}} results', { dynamicValue: searchResults?.length })}</span>
            </div>
            {searchResults.length > 0 ? (searchResults?.map((operator, index) => (
                <OperatorItem
                    key={index}
                    registerId={operator.id}
                    companyName={operator.companyName}
                    companyType={operator.companyType}
                    taxNumber={operator.taxNumber}
                    t={t}
                />
            ))) : (<p>{t('register-list:loading', 'Loading...')}</p>)}
        </div>
    );
};

export default RegisterListItem;
