/* tslint:disable */
/* eslint-disable */
/**
 * MRVPP PUBLIC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CantonDto } from '../models';
// @ts-ignore
import { CertificateNumberValidityResultDto } from '../models';
// @ts-ignore
import { CheckCertificateNumberRequestDto } from '../models';
// @ts-ignore
import { CodebookDto } from '../models';
// @ts-ignore
import { GetRegistersForPublicQuery } from '../models';
// @ts-ignore
import { MunicipalityDto } from '../models';
// @ts-ignore
import { RefrigerantTypeDto } from '../models';
// @ts-ignore
import { RegisterDetailsDto } from '../models';
// @ts-ignore
import { RegisterForPublicDto } from '../models';
// @ts-ignore
import { RequestStatus } from '../models';
// @ts-ignore
import { StateEntityDto } from '../models';
/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCodebooksBusinessActivitiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Public/codebooks/business-activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCodebooksCantonsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Public/codebooks/cantons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCodebooksGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Public/codebooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCodebooksMunicipalitiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Public/codebooks/municipalities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCodebooksStateEntitiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Public/codebooks/state-entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} idNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicOrganizationExistsIdNumberGet: async (idNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNumber' is not null or undefined
            assertParamExists('apiPublicOrganizationExistsIdNumberGet', 'idNumber', idNumber)
            const localVarPath = `/api/Public/organization-exists/{idNumber}`
                .replace(`{${"idNumber"}}`, encodeURIComponent(String(idNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicRefrigerantTypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Public/refrigerant-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicRegistersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPublicRegistersIdGet', 'id', id)
            const localVarPath = `/api/Public/registers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetRegistersForPublicQuery} [getRegistersForPublicQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicRegistersPost: async (getRegistersForPublicQuery?: GetRegistersForPublicQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Public/registers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getRegistersForPublicQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckCertificateNumberRequestDto} [checkCertificateNumberRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicRequestsCertificateNumbersValidityPost: async (checkCertificateNumberRequestDto?: CheckCertificateNumberRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Public/requests/certificate-numbers-validity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkCertificateNumberRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isFromPublic] 
         * @param {string} [idNumber] 
         * @param {string} [companyName] 
         * @param {string} [companyEmailAddress] 
         * @param {string} [companyPhoneNumber] 
         * @param {string} [websiteUrl] 
         * @param {string} [taxNumber] 
         * @param {string} [responsiblePersonFullName] 
         * @param {string} [responsiblePersonFunction] 
         * @param {string} [contactPersonFirstName] 
         * @param {string} [contactPersonLastName] 
         * @param {string} [contactPersonEmail] 
         * @param {string} [address] 
         * @param {string} [place] 
         * @param {string} [postCode] 
         * @param {string} [comments] 
         * @param {string} [municipalityId] 
         * @param {string} [stateEntityId] 
         * @param {string} [licenseId] 
         * @param {string} [licenseDuration] 
         * @param {Array<string>} [certificationNumbers] 
         * @param {number} [totalNumberOfServiceTechnians] 
         * @param {boolean} [meetsEquipmentRegulations] 
         * @param {string} [languageId] 
         * @param {number} [type] 
         * @param {RequestStatus} [status] 
         * @param {number} [companyType] 
         * @param {number} [areaOfExpertise] 
         * @param {string} [businessActivityId] 
         * @param {Array<File>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicRequestsSubmitPost: async (isFromPublic?: boolean, idNumber?: string, companyName?: string, companyEmailAddress?: string, companyPhoneNumber?: string, websiteUrl?: string, taxNumber?: string, responsiblePersonFullName?: string, responsiblePersonFunction?: string, contactPersonFirstName?: string, contactPersonLastName?: string, contactPersonEmail?: string, address?: string, place?: string, postCode?: string, comments?: string, municipalityId?: string, stateEntityId?: string, licenseId?: string, licenseDuration?: string, certificationNumbers?: Array<string>, totalNumberOfServiceTechnians?: number, meetsEquipmentRegulations?: boolean, languageId?: string, type?: number, status?: RequestStatus, companyType?: number, areaOfExpertise?: number, businessActivityId?: string, attachments?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Public/requests/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (isFromPublic !== undefined) { 
                localVarFormParams.append('IsFromPublic', isFromPublic as any);
            }
    
            if (idNumber !== undefined) { 
                localVarFormParams.append('IdNumber', idNumber as any);
            }
    
            if (companyName !== undefined) { 
                localVarFormParams.append('CompanyName', companyName as any);
            }
    
            if (companyEmailAddress !== undefined) { 
                localVarFormParams.append('CompanyEmailAddress', companyEmailAddress as any);
            }
    
            if (companyPhoneNumber !== undefined) { 
                localVarFormParams.append('CompanyPhoneNumber', companyPhoneNumber as any);
            }
    
            if (websiteUrl !== undefined) { 
                localVarFormParams.append('WebsiteUrl', websiteUrl as any);
            }
    
            if (taxNumber !== undefined) { 
                localVarFormParams.append('TaxNumber', taxNumber as any);
            }
    
            if (responsiblePersonFullName !== undefined) { 
                localVarFormParams.append('ResponsiblePersonFullName', responsiblePersonFullName as any);
            }
    
            if (responsiblePersonFunction !== undefined) { 
                localVarFormParams.append('ResponsiblePersonFunction', responsiblePersonFunction as any);
            }
    
            if (contactPersonFirstName !== undefined) { 
                localVarFormParams.append('ContactPersonFirstName', contactPersonFirstName as any);
            }
    
            if (contactPersonLastName !== undefined) { 
                localVarFormParams.append('ContactPersonLastName', contactPersonLastName as any);
            }
    
            if (contactPersonEmail !== undefined) { 
                localVarFormParams.append('ContactPersonEmail', contactPersonEmail as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
    
            if (place !== undefined) { 
                localVarFormParams.append('Place', place as any);
            }
    
            if (postCode !== undefined) { 
                localVarFormParams.append('PostCode', postCode as any);
            }
    
            if (comments !== undefined) { 
                localVarFormParams.append('Comments', comments as any);
            }
    
            if (municipalityId !== undefined) { 
                localVarFormParams.append('MunicipalityId', municipalityId as any);
            }
    
            if (stateEntityId !== undefined) { 
                localVarFormParams.append('StateEntityId', stateEntityId as any);
            }
    
            if (licenseId !== undefined) { 
                localVarFormParams.append('LicenseId', licenseId as any);
            }
    
            if (licenseDuration !== undefined) { 
                localVarFormParams.append('LicenseDuration', licenseDuration as any);
            }
                if (certificationNumbers) {
                certificationNumbers.forEach((element) => {
                    localVarFormParams.append('CertificationNumbers', element as any);
                })
            }

    
            if (totalNumberOfServiceTechnians !== undefined) { 
                localVarFormParams.append('TotalNumberOfServiceTechnians', totalNumberOfServiceTechnians as any);
            }
    
            if (meetsEquipmentRegulations !== undefined) { 
                localVarFormParams.append('MeetsEquipmentRegulations', meetsEquipmentRegulations as any);
            }
    
            if (languageId !== undefined) { 
                localVarFormParams.append('LanguageId', languageId as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
    
            if (status !== undefined) { 
                localVarFormParams.append('Status', status as any);
            }
    
            if (companyType !== undefined) { 
                localVarFormParams.append('CompanyType', companyType as any);
            }
    
            if (areaOfExpertise !== undefined) { 
                localVarFormParams.append('AreaOfExpertise', areaOfExpertise as any);
            }
    
            if (businessActivityId !== undefined) { 
                localVarFormParams.append('BusinessActivityId', businessActivityId as any);
            }
                if (attachments) {
                attachments.forEach((element) => {
                    localVarFormParams.append('Attachments', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicUserExistsEmailGet: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiPublicUserExistsEmailGet', 'email', email)
            // const localVarPath = `/users/available/{email}`
            const localVarPath = `/api/Public/user-exists/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
       * 
       * @param {string} taxnumber 
       * @param {*} [options] Override http request option.
       * @throws {RequiredError}
       */
        apiPublicUserExistsTaxNumberGet: async (taxnumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiPublicUserExistsTaxNumberGet', 'TaxNumber', taxnumber)
            // const localVarPath = `/users/available/{email}`
            const localVarPath = `/api/Public/user-exist/{taxnumber}`
                .replace(`{${"taxnumber"}}`, encodeURIComponent(String(taxnumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicCodebooksBusinessActivitiesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodebookDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicCodebooksBusinessActivitiesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicCodebooksCantonsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CantonDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicCodebooksCantonsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicCodebooksGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: Array<CodebookDto>; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicCodebooksGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicCodebooksMunicipalitiesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MunicipalityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicCodebooksMunicipalitiesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicCodebooksStateEntitiesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StateEntityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicCodebooksStateEntitiesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} idNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicOrganizationExistsIdNumberGet(idNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicOrganizationExistsIdNumberGet(idNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicRefrigerantTypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RefrigerantTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicRefrigerantTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicRegistersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicRegistersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetRegistersForPublicQuery} [getRegistersForPublicQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicRegistersPost(getRegistersForPublicQuery?: GetRegistersForPublicQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RegisterForPublicDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicRegistersPost(getRegistersForPublicQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckCertificateNumberRequestDto} [checkCertificateNumberRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicRequestsCertificateNumbersValidityPost(checkCertificateNumberRequestDto?: CheckCertificateNumberRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CertificateNumberValidityResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicRequestsCertificateNumbersValidityPost(checkCertificateNumberRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isFromPublic] 
         * @param {string} [idNumber] 
         * @param {string} [companyName] 
         * @param {string} [companyEmailAddress] 
         * @param {string} [companyPhoneNumber] 
         * @param {string} [websiteUrl] 
         * @param {string} [taxNumber] 
         * @param {string} [responsiblePersonFullName] 
         * @param {string} [responsiblePersonFunction] 
         * @param {string} [contactPersonFirstName] 
         * @param {string} [contactPersonLastName] 
         * @param {string} [contactPersonEmail] 
         * @param {string} [address] 
         * @param {string} [place] 
         * @param {string} [postCode] 
         * @param {string} [comments] 
         * @param {string} [municipalityId] 
         * @param {string} [stateEntityId] 
         * @param {string} [licenseId] 
         * @param {string} [licenseDuration] 
         * @param {Array<string>} [certificationNumbers] 
         * @param {number} [totalNumberOfServiceTechnians] 
         * @param {boolean} [meetsEquipmentRegulations] 
         * @param {string} [languageId] 
         * @param {number} [type] 
         * @param {RequestStatus} [status] 
         * @param {number} [companyType] 
         * @param {number} [areaOfExpertise] 
         * @param {string} [businessActivityId] 
         * @param {Array<File>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicRequestsSubmitPost(isFromPublic?: boolean, idNumber?: string, companyName?: string, companyEmailAddress?: string, companyPhoneNumber?: string, websiteUrl?: string, taxNumber?: string, responsiblePersonFullName?: string, responsiblePersonFunction?: string, contactPersonFirstName?: string, contactPersonLastName?: string, contactPersonEmail?: string, address?: string, place?: string, postCode?: string, comments?: string, municipalityId?: string, stateEntityId?: string, licenseId?: string, licenseDuration?: string, certificationNumbers?: Array<string>, totalNumberOfServiceTechnians?: number, meetsEquipmentRegulations?: boolean, languageId?: string, type?: number, status?: RequestStatus, companyType?: number, areaOfExpertise?: number, businessActivityId?: string, attachments?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicRequestsSubmitPost(isFromPublic, idNumber, companyName, companyEmailAddress, companyPhoneNumber, websiteUrl, taxNumber, responsiblePersonFullName, responsiblePersonFunction, contactPersonFirstName, contactPersonLastName, contactPersonEmail, address, place, postCode, comments, municipalityId, stateEntityId, licenseId, licenseDuration, certificationNumbers, totalNumberOfServiceTechnians, meetsEquipmentRegulations, languageId, type, status, companyType, areaOfExpertise, businessActivityId, attachments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicUserExistsEmailGet(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicUserExistsEmailGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} taxnumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicUserExistsTaxNumberGet(taxnumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicUserExistsTaxNumberGet(taxnumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCodebooksBusinessActivitiesGet(options?: any): AxiosPromise<Array<CodebookDto>> {
            return localVarFp.apiPublicCodebooksBusinessActivitiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCodebooksCantonsGet(options?: any): AxiosPromise<Array<CantonDto>> {
            return localVarFp.apiPublicCodebooksCantonsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCodebooksGet(options?: any): AxiosPromise<{ [key: string]: Array<CodebookDto>; }> {
            return localVarFp.apiPublicCodebooksGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCodebooksMunicipalitiesGet(options?: any): AxiosPromise<Array<MunicipalityDto>> {
            return localVarFp.apiPublicCodebooksMunicipalitiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicCodebooksStateEntitiesGet(options?: any): AxiosPromise<Array<StateEntityDto>> {
            return localVarFp.apiPublicCodebooksStateEntitiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} idNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicOrganizationExistsIdNumberGet(idNumber: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiPublicOrganizationExistsIdNumberGet(idNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicRefrigerantTypesGet(options?: any): AxiosPromise<Array<RefrigerantTypeDto>> {
            return localVarFp.apiPublicRefrigerantTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicRegistersIdGet(id: string, options?: any): AxiosPromise<RegisterDetailsDto> {
            return localVarFp.apiPublicRegistersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetRegistersForPublicQuery} [getRegistersForPublicQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicRegistersPost(getRegistersForPublicQuery?: GetRegistersForPublicQuery, options?: any): AxiosPromise<Array<RegisterForPublicDto>> {
            return localVarFp.apiPublicRegistersPost(getRegistersForPublicQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckCertificateNumberRequestDto} [checkCertificateNumberRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicRequestsCertificateNumbersValidityPost(checkCertificateNumberRequestDto?: CheckCertificateNumberRequestDto, options?: any): AxiosPromise<Array<CertificateNumberValidityResultDto>> {
            return localVarFp.apiPublicRequestsCertificateNumbersValidityPost(checkCertificateNumberRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isFromPublic] 
         * @param {string} [idNumber] 
         * @param {string} [companyName] 
         * @param {string} [companyEmailAddress] 
         * @param {string} [companyPhoneNumber] 
         * @param {string} [websiteUrl] 
         * @param {string} [taxNumber] 
         * @param {string} [responsiblePersonFullName] 
         * @param {string} [responsiblePersonFunction] 
         * @param {string} [contactPersonFirstName] 
         * @param {string} [contactPersonLastName] 
         * @param {string} [contactPersonEmail] 
         * @param {string} [address] 
         * @param {string} [place] 
         * @param {string} [postCode] 
         * @param {string} [comments] 
         * @param {string} [municipalityId] 
         * @param {string} [stateEntityId] 
         * @param {string} [licenseId] 
         * @param {string} [licenseDuration] 
         * @param {Array<string>} [certificationNumbers] 
         * @param {number} [totalNumberOfServiceTechnians] 
         * @param {boolean} [meetsEquipmentRegulations] 
         * @param {string} [languageId] 
         * @param {number} [type] 
         * @param {RequestStatus} [status] 
         * @param {number} [companyType] 
         * @param {number} [areaOfExpertise] 
         * @param {string} [businessActivityId] 
         * @param {Array<File>} [attachments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicRequestsSubmitPost(isFromPublic?: boolean, idNumber?: string, companyName?: string, companyEmailAddress?: string, companyPhoneNumber?: string, websiteUrl?: string, taxNumber?: string, responsiblePersonFullName?: string, responsiblePersonFunction?: string, contactPersonFirstName?: string, contactPersonLastName?: string, contactPersonEmail?: string, address?: string, place?: string, postCode?: string, comments?: string, municipalityId?: string, stateEntityId?: string, licenseId?: string, licenseDuration?: string, certificationNumbers?: Array<string>, totalNumberOfServiceTechnians?: number, meetsEquipmentRegulations?: boolean, languageId?: string, type?: number, status?: RequestStatus, companyType?: number, areaOfExpertise?: number, businessActivityId?: string, attachments?: Array<File>, options?: any): AxiosPromise<string> {
            return localVarFp.apiPublicRequestsSubmitPost(isFromPublic, idNumber, companyName, companyEmailAddress, companyPhoneNumber, websiteUrl, taxNumber, responsiblePersonFullName, responsiblePersonFunction, contactPersonFirstName, contactPersonLastName, contactPersonEmail, address, place, postCode, comments, municipalityId, stateEntityId, licenseId, licenseDuration, certificationNumbers, totalNumberOfServiceTechnians, meetsEquipmentRegulations, languageId, type, status, companyType, areaOfExpertise, businessActivityId, attachments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicUserExistsEmailGet(email: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiPublicUserExistsEmailGet(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiPublicOrganizationExistsIdNumberGet operation in PublicApi.
 * @export
 * @interface PublicApiApiPublicOrganizationExistsIdNumberGetRequest
 */
export interface PublicApiApiPublicOrganizationExistsIdNumberGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicOrganizationExistsIdNumberGet
     */
    readonly idNumber: string
}

/**
 * Request parameters for apiPublicRegistersIdGet operation in PublicApi.
 * @export
 * @interface PublicApiApiPublicRegistersIdGetRequest
 */
export interface PublicApiApiPublicRegistersIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRegistersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for apiPublicRegistersPost operation in PublicApi.
 * @export
 * @interface PublicApiApiPublicRegistersPostRequest
 */
export interface PublicApiApiPublicRegistersPostRequest {
    /**
     * 
     * @type {GetRegistersForPublicQuery}
     * @memberof PublicApiApiPublicRegistersPost
     */
    readonly getRegistersForPublicQuery?: GetRegistersForPublicQuery
}

/**
 * Request parameters for apiPublicRequestsCertificateNumbersValidityPost operation in PublicApi.
 * @export
 * @interface PublicApiApiPublicRequestsCertificateNumbersValidityPostRequest
 */
export interface PublicApiApiPublicRequestsCertificateNumbersValidityPostRequest {
    /**
     * 
     * @type {CheckCertificateNumberRequestDto}
     * @memberof PublicApiApiPublicRequestsCertificateNumbersValidityPost
     */
    readonly checkCertificateNumberRequestDto?: CheckCertificateNumberRequestDto
}

/**
 * Request parameters for apiPublicRequestsSubmitPost operation in PublicApi.
 * @export
 * @interface PublicApiApiPublicRequestsSubmitPostRequest
 */
export interface PublicApiApiPublicRequestsSubmitPostRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly isFromPublic?: boolean

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly idNumber?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly companyName?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly companyEmailAddress?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly companyPhoneNumber?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly websiteUrl?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly taxNumber?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly responsiblePersonFullName?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly responsiblePersonFunction?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly contactPersonFirstName?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly contactPersonLastName?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly contactPersonEmail?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly address?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly place?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly postCode?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly comments?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly municipalityId?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly stateEntityId?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly licenseId?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly licenseDuration?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly certificationNumbers?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly totalNumberOfServiceTechnians?: number

    /**
     * 
     * @type {boolean}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly meetsEquipmentRegulations?: boolean

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly languageId?: string

    /**
     * 
     * @type {number}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly type?: number

    /**
     * 
     * @type {RequestStatus}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly status?: RequestStatus

    /**
     * 
     * @type {number}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly companyType?: number

    /**
     * 
     * @type {number}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly areaOfExpertise?: number

    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly businessActivityId?: string

    /**
     * 
     * @type {Array<File>}
     * @memberof PublicApiApiPublicRequestsSubmitPost
     */
    readonly attachments?: Array<File>
}

/**
 * Request parameters for apiPublicUserExistsEmailGet operation in PublicApi.
 * @export
 * @interface PublicApiApiPublicUserExistsEmailGetRequest
 */
export interface PublicApiApiPublicUserExistsEmailGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicUserExistsEmailGet
     */
    readonly email: string
}
export interface PublicApiApiPublicUserExistsTaxNumberGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiApiPublicUserExistsEmailGet
     */
    readonly taxnumber: string
}
/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public apiPublicCodebooksBusinessActivitiesGet(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicCodebooksBusinessActivitiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public apiPublicCodebooksCantonsGet(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicCodebooksCantonsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public apiPublicCodebooksGet(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicCodebooksGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public apiPublicCodebooksMunicipalitiesGet(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicCodebooksMunicipalitiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public apiPublicCodebooksStateEntitiesGet(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicCodebooksStateEntitiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiApiPublicOrganizationExistsIdNumberGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public apiPublicOrganizationExistsIdNumberGet(requestParameters: PublicApiApiPublicOrganizationExistsIdNumberGetRequest, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicOrganizationExistsIdNumberGet(requestParameters.idNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public apiPublicRefrigerantTypesGet(options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicRefrigerantTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiApiPublicRegistersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public apiPublicRegistersIdGet(requestParameters: PublicApiApiPublicRegistersIdGetRequest, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicRegistersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiApiPublicRegistersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public apiPublicRegistersPost(requestParameters: PublicApiApiPublicRegistersPostRequest = {}, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicRegistersPost(requestParameters.getRegistersForPublicQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiApiPublicRequestsCertificateNumbersValidityPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public apiPublicRequestsCertificateNumbersValidityPost(requestParameters: PublicApiApiPublicRequestsCertificateNumbersValidityPostRequest = {}, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicRequestsCertificateNumbersValidityPost(requestParameters.checkCertificateNumberRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiApiPublicRequestsSubmitPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public apiPublicRequestsSubmitPost(requestParameters: PublicApiApiPublicRequestsSubmitPostRequest = {}, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicRequestsSubmitPost(requestParameters.isFromPublic, requestParameters.idNumber, requestParameters.companyName, requestParameters.companyEmailAddress, requestParameters.companyPhoneNumber, requestParameters.websiteUrl, requestParameters.taxNumber, requestParameters.responsiblePersonFullName, requestParameters.responsiblePersonFunction, requestParameters.contactPersonFirstName, requestParameters.contactPersonLastName, requestParameters.contactPersonEmail, requestParameters.address, requestParameters.place, requestParameters.postCode, requestParameters.comments, requestParameters.municipalityId, requestParameters.stateEntityId, requestParameters.licenseId, requestParameters.licenseDuration, requestParameters.certificationNumbers, requestParameters.totalNumberOfServiceTechnians, requestParameters.meetsEquipmentRegulations, requestParameters.languageId, requestParameters.type, requestParameters.status, requestParameters.companyType, requestParameters.areaOfExpertise, requestParameters.businessActivityId, requestParameters.attachments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiApiPublicUserExistsEmailGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public apiPublicUserExistsEmailGet(requestParameters: PublicApiApiPublicUserExistsEmailGetRequest, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicUserExistsEmailGet(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
    * 
    * @param {PublicApiApiPublicUserExistsEmailGetRequest} requestParameters Request parameters.
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    * @memberof PublicApi
    */
    public apiPublicUserExistsTaxNumberGet(requestParameters: PublicApiApiPublicUserExistsTaxNumberGetRequest, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).apiPublicUserExistsTaxNumberGet(requestParameters.taxnumber, options).then((request) => request(this.axios, this.basePath));
    }
}
