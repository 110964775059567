import "./home.css";
import SearchComponent from "../../components/search/searchComponent";
import RegisterListItem from "../../components/register/RegisterListItem";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// const requestApi = new RequestsApi();

export const Home = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const handleResponse = ({ data, isLoading }: any) => {
    setIsLoading(isLoading);
    setSearchResults(data);
  };

  return (
    <div className="home-container">
      <div className="home-background-overlay"></div>
      <div className="home-overlay"></div>

      <SearchComponent onResponse={handleResponse} />
      {loading ? (
        <div className="p-24">
          <span className="loader"></span>
        </div>
      ) : null}
      {searchResults.length !== 0 ? (
        <RegisterListItem searchResults={searchResults} />
      ) : null}
    </div>
  );
};
