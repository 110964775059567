import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { useLocation } from 'react-router-dom';

export const Layout = ({ children }) => {

  const [isRequestPage, setIsRequestPage] = useState(false)
  const location = useLocation();


  useEffect(() => {
    setIsRequestPage(location.pathname.includes('requests/create') || location.pathname.includes('register'));
  }, [location]);

  return (
    <div>
      <NavMenu />
      {isRequestPage ? children :
        (<Container style={{ padding: 0 }} tag="main">
          {children}
        </Container>)}
    </div>
  );
};

