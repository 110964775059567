/* tslint:disable */
/* eslint-disable */
/**
 * MRVPP PUBLIC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CompanyType = {
    EEProducer: 1,
    EEConsumer: 2,
    EESupplier: 3
} as const;

export type CompanyType = typeof CompanyType[keyof typeof CompanyType];



    