import React, { useContext, useEffect } from "react";
import "./search-style.css";
import { CommonDataContext } from "contexts/CommonDataContext/CommonDataContext";
import { Button, Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { CompanyType, MunicipalityDto } from "api/models";
import { RequestCompanyType } from "api/models/request-company-type";
import { PublicApi } from "api/api";

const { Option } = Select;

const publicApi = new PublicApi();

const SearchComponent = ({ onResponse }: any) => {
  const { t } = useTranslation();

  /**
   * Context API
   */
  // const commonData = useContext(CommonDataContext) as any;
  // const { municipalities } = commonData;

  const handleSearch = async (data: any) => {
    onResponse({ data: [], isLoading: true });
    const request = await publicApi.apiPublicRegistersPost({
      getRegistersForPublicQuery: data,
    });
    onResponse({ data: request.data, isLoading: false });
  };

  useEffect(() => {
    handleSearch({});
  }, []);

  return (
    <div className="search-container">
      <div className="search-container-overlay"></div>
      <h1 className="search-title text-5xl font-semibold mb-5">
        {t(
          "home:description",
          "CLICK REQUEST TO REGISTER"
        )}
      </h1>
      <Form
        name="searchForm"
        className="search-container-form"
        onFinish={handleSearch}
      >
        <Form.Item name="search" className="w-full ">
          <Input
            type="text"
            className="input"
            placeholder={t(
              "public-search:input-placeholder",
              "What are you looking for?"
            )}
          />
        </Form.Item>
        <Form.Item name="companyType" className="w-full">
          <Select
            placeholder={t(
              "search:select-company-type",
              "Select a company type"
            )}
            size="large"
            className="input"
            id=""
          >
            <Option
              key={`company-type-${CompanyType.EEProducer}`}
              value={CompanyType.EEProducer}
            >
              {t(`requests:company-type.${CompanyType.EEProducer}}`, "EEProducer")}
            </Option>
            <Option
              key={`company-type-${CompanyType.EEConsumer}`}
              value={CompanyType.EEConsumer}
            >
              {t(
                `requests:company-type.${CompanyType.EEConsumer}}`,
                "EEConsumer"
              )}
            </Option>
            <Option
              key={`company-type-${CompanyType.EESupplier}`}
              value={CompanyType.EESupplier}
            >
              {t(
                `requests:company-type.${RequestCompanyType.NUMBER_3}}`,
                "EESupplier"
              )}
            </Option>
          </Select>
        </Form.Item>

        <Button
          type="primary"
          className="bg-red-500 hover:!bg-red-700 md:mb-0"
          htmlType="submit"
        >
          {t("search:button-show-result", "Show Results")}
        </Button>
      </Form>
    </div>
  );
};

export default SearchComponent;
