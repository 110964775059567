import { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Upload, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { CodebookDto, MunicipalityDto, RequestType } from "api/models";
import { UploadOutlined } from "@ant-design/icons";
import "./create-request.css";
import { CommonDataContext } from "contexts/CommonDataContext/CommonDataContext";
import { PublicApi } from "api/api";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import { useNavigate, useParams } from "react-router-dom";
import openNotificationWithIcon from "utility/notification";
import { RequestsApi } from "api/clients/reqeust-api";

const publicApi = new PublicApi();

const requestsApi = new RequestsApi();
const CreateRequestsForm = () => {
  /**
   * Transalte
   */
  const { t } = useTranslation();

  const navigate = useNavigate();

  /**
   * Forms
   */
  const [form] = Form.useForm();

  // const [searchParams] = useSearchParams();
  const { type } = useParams();
  const parsedType = parseInt(type ?? "0") as RequestType;

  /**
   * Context API
   */
  const commonData = useContext(CommonDataContext) as any;
  const { municipalities, cantons, stateEntities } = commonData;

  /**
   * State
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [businessActivities, setBusinessActivities] = useState<
    CodebookDto[] | null | undefined
  >([]);
  const [certificateNumbers, setCertificateNumbers] = useState<string[]>([]);
  const [certificateNumbersExpanded, setCertificateNumbersExpanded] = useState<
    string[]
  >([]);
  const [certificateNumberValidity, setCertificateNumberValidity] = useState<{
    checking: boolean;
    valid: boolean;
    errorMessage: string;
  }>({ checking: false, valid: true, errorMessage: "" });
  const [isMegaPermission, setIsMegaPermission] = useState(false);
  const [isSupplyLicense, setIsSupplyLicense] = useState(false);
  const [isBalancingContract, setIsBalancingContract] = useState(false);
  const [isMarketAgreement, setIsMarketAgreement] = useState(false);
  const [isInformationTrue, setIsInformationTrue] = useState(false);
  const [isIntETCCode, setIsIntETCCode] = useState(false);

  /**
   * Statics
   */
  const isRegistrationOfServiceCompany =
    parsedType === RequestType.RegistrationOfEEConsumers;
  const isRegistrationOfImportersExporters =
    parsedType === RequestType.RegistrationOfEESuppliers;

  const onFinish = async (data: any) => {

    try {
      setIsSubmitting(true);
      await searchForContactPersonEmail();
      await searchForTaxNumber();

      if (isFormInvalid()) {
        openNotificationWithIcon(
          "error",
          t(
            "requests:create.company-not-found",
            "You are already part of the system, please sign in with your credentials and make requests from there!"
          )
        );
        return;
      }

      const languageId =
        localStorage.getItem("i18nextLanguageId") ||
        "64924d45-7f38-11ee-85aa-0242ac110004";
      const res = await requestsApi.apiRequestsPost({
        ...form.getFieldsValue(),
        isLoggedInAsCompany: false,
        isFromPublic: true,
        companyName: form.getFieldValue("companyName"),
        taxNumber: form.getFieldValue("taxNumber"),
        contactPersonFirstName: form.getFieldValue("contactPersonFirstName"),
        contactPersonLastName: form.getFieldValue("contactPersonLastName"),
        contactPersonEmail: form.getFieldValue("contactPersonEmail"),
        contactPersonPhone: form.getFieldValue("contactPersonPhone"),
        isMegaPermission: form.getFieldValue("isMegaPermission"),
        isSupplyLicense: form.getFieldValue("isSupplyLicense"),
        isBalancingContract: form.getFieldValue("isBalancingContract"),
        isMarketAgreement: form.getFieldValue("isMarketAgreement"),
        isIntETCCode: form.getFieldValue("isIntETCCode"),
        isInformationTrue: form.getFieldValue("isInformationTrue"),
        type: parsedType,
        companyType: parsedType,
        languageId: languageId,
      });

      const requestID: any = res?.data;
      if (requestID == "Already exist") {
        openNotificationWithIcon(
          "error",
          t("requests:create-error", `Request Already exist`)
        );
      } else {
        navigate("/confirmation", {
          state: {
            requestId: res.data,
            ...data,
          },
        });
        form.resetFields();
        openNotificationWithIcon(
          "success",
          requestID + " " + t(
            "requests:create-success",
            `Request created successfully`)
        );

      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormInvalid = () =>
    form.getFieldsError().some((item) => item.errors.length > 0);

  const searchForContactPersonEmail = async () => {
    const contactPersonEmail = form.getFieldValue("contactPersonEmail");
    const { data } = await publicApi.apiPublicUserExistsEmailGet({
      email: contactPersonEmail,
    });
    if (data) {
      form.setFields([
        {
          name: "contactPersonEmail",
          errors: [
            t(
              "request:already-exist",
              "You are already part of the system, please sign in with your credentials and make requests from there!"
            ),
          ],
        },
      ]);
    }
  };

  const searchForTaxNumber = async () => {
    const taxNumber = form.getFieldValue("taxNumber");
    const { data } = await publicApi.apiPublicUserExistsTaxNumberGet({
      taxnumber: taxNumber,
    });
    if (data) {
      form.setFields([
        {
          name: "taxNumber",
          errors: [
            t(
              "request:already-exist",
              "You are already part of the system, please sign in with your credentials and make requests from there!"
            ),
          ],
        },
      ]);
    }
  };

  const handleCheckboxChange = (checked: any) => {
    form.setFieldValue("meetsEquipmentRegulations", checked);
  };

  const handleFileUpload = (info: UploadChangeParam) => {
    form.setFieldValue(
      "attachments",
      info.fileList.map((x) => x.originFileObj)
    );
  };

  const handleFileRemove = (fileToRemove: UploadFile) => {
    const currentFiles = form.getFieldValue("attachments") || [];
    const newFiles = currentFiles.filter(
      (file: UploadFile) => file.uid !== fileToRemove.uid
    );
    form.setFieldValue("attachments", newFiles);
    // Return true to confirm the removal, or you can return a promise if you have asynchronous operations
    return true;
  };

  const onMunicipalityChange = (value: number | undefined) => {
    const municipality = municipalities.find(
      (item: MunicipalityDto) => item.id === value
    );
    if (municipality) {
      form.setFieldsValue({
        cantonId: municipality.cantonId,
        entityId: municipality.stateEntityId,
      });
    }
  };

  /**
   * Use Effect
   */
  useEffect(() => {
    const fetchBusinessActivities = async () => {
      const { data } =
        await publicApi.apiPublicCodebooksBusinessActivitiesGet();
      setBusinessActivities(data);
    };

    fetchBusinessActivities();
  }, []);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedType]);

  const validateField = (fieldName: any, translationKey: any, value: any) => {
    if (!value || value.trim() === "") {
      return t("validations.required-field", {
        field: t(translationKey, fieldName),
        defaultValue: "{{field}} is required.",
      });
    }
  };

  return (
    <>
      {/* <div className="request-overlay"> */}
      {parsedType === 1 && (
        <div className="request-overlay overlay-1">
          <p className="text-3xl font-semibold">
            {t("request:title-registration-producers", "Create Request For Registration Of EE Producers.")}
          </p>
        </div>
      )}
      {parsedType === 2 && (
        <div className="request-overlay overlay-2">
          <p className="text-3xl font-semibold">
            {t("request:title-registration-consumers", "Create Request For Registration Of EE Consumers.")}
          </p>
        </div>
      )}
      {parsedType === 3 && (
        <div className="request-overlay overlay-3">
          <p className="text-3xl font-semibold">
            {t("request:title-registration-suppliers", "Create Request For Registration Of EE Suppliers.")}
          </p>
        </div>
      )}
      {/* </div> */}
      <div className="request-container">
        <Form
          form={form}
          name="request"
          requiredMark
          className="mt-5"
          onFinish={onFinish}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          scrollToFirstError
        >
          <div className="section-container flex justify-center flex-col md:flex-row ">
            <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
              <h1 className="text-lg font-medium">
                {t("request:company-information", "Company Information")}
              </h1>
              <div className="flex flex-col">
                <Form.Item
                  name="companyName"
                  label={t("requests:create.company-name", "Company Name")}
                  rules={[
                    {
                      required: true,
                      validator(_, value) {
                        const errorMessage = validateField(
                          "Company Name",
                          "request:validations.comapny-name-required",
                          value
                        );
                        if (errorMessage) {
                          return Promise.reject(errorMessage);
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input className="w-full" />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="taxNumber"
                  label={t("global:tax-number", "Tax Number")}
                  rules={[
                    {
                      len: 13,
                      required: true,
                      message: t(
                        "request:create-tax-number-validation",
                        "Tax Number should be at most 13 characters"
                      ),
                    },
                  ]}
                >
                  <Input
                    className="w-full"
                    onBlur={searchForTaxNumber}
                    minLength={13}
                    maxLength={13}
                    onInput={(e: any) => {
                      e.preventDefault();
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      e.target.value = value;
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="section-container flex flex-col md:flex-row justify-center">
            <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
              <h1 className="text-lg font-medium">
                {t("request:company-contact", "Company Contact Information")}
              </h1>

              <div className="flex flex-col">
                <Form.Item
                  name="contactPersonFirstName"
                  label={t(
                    "requests:create.responsible-person-first-name",
                    "Responsible Person (First Name)"
                  )}
                  rules={[
                    {
                      required: true,
                      validator(_, value) {
                        const errorMessage = validateField(
                          "Responsible person first name",
                          "request:validations.first-name-required",
                          value
                        );
                        if (errorMessage) {
                          return Promise.reject(errorMessage);
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>

              <div className="flex flex-col">
                <Form.Item
                  name="contactPersonLastName"
                  label={t(
                    "requests:create.responsible-person-second-name",
                    "Responsible Person (Second Name)"
                  )}
                  rules={[
                    {
                      required: true,
                      validator(_, value) {
                        const errorMessage = validateField(
                          "Responsible person second name",
                          "request:validations.last-name-required",
                          value
                        );
                        if (errorMessage) {
                          return Promise.reject(errorMessage);
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="flex flex-col">
                <Form.Item
                  name="contactPersonEmail"
                  label={t(
                    "global:contact-person-email",
                    "Contact Person Email"
                  )}
                  tooltip={t(
                    "requests:create.contact-person-email-to-login",
                    "This email will be used for logging into the app"
                  )}
                  rules={[
                    {
                      required: true,
                      message: t(
                        "users.create:email-required",
                        "Email is required."
                      ),
                    },
                    {
                      pattern:
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: t(
                        "users.create:email-validation",
                        "Invalid email format."
                      ),
                    },
                  ]}
                >
                  <Input value={""} onBlur={searchForContactPersonEmail} />
                </Form.Item>
              </div>
              {/* <div className="flex flex-col">
                <Form.Item
                  name="comments"
                  label={t("global.comment", "Comment")}
                >
                  <TextArea style={{ width: "100%" }} rows={3} />
                </Form.Item>
              </div> */}
              <div className="flex flex-col">
                <Form.Item
                  name="contactPersonPhone"
                  label={t("global:contact-person-phone", "Contact Person Phone Number")}
                  rules={[
                    {
                      required: true,
                      message: t("global:validations-contact-person-phone", "Phone Number is required."),
                    },
                  ]}
                >
                  <Input
                    className="w-full"
                    onInput={(e: any) => {
                      e.preventDefault();
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      e.target.value = value;
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          {type?.toString() === "2" && (
            <div className="section-container flex flex-col md:flex-row justify-center">
              <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
                <div className="flex flex-col">
                  <Form.Item name="isMegaPermission" valuePropName="checked">
                    <Checkbox
                      onChange={() => setIsMegaPermission(!isMegaPermission)}
                    >
                      {t(
                        "requests:consumer-measuring-point-agree",
                        "I agree that Mega Concept can take over the measuring points and consumption from the EVN system on my behalf."
                      )}
                    </Checkbox>
                  </Form.Item>
                </div>
              </div>
            </div>
          )}
          {type?.toString() === "3" && (
            <div className="section-container flex flex-col md:flex-row justify-center">
              <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
                <div className="flex flex-col">
                  <Form.Item name="isSupplyLicense" valuePropName="checked">
                    <Checkbox
                      onChange={() => setIsSupplyLicense(!isSupplyLicense)}
                    >
                      {t("requests:supllier-license", "Supply License")}
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="flex flex-col">
                  <Form.Item name="isIntETCCode" valuePropName="checked">
                    <Checkbox onChange={() => setIsIntETCCode(!isIntETCCode)}>
                      {t("requests:supllier-international", "International EIC Code")}
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="flex flex-col">
                  <Form.Item name="isBalancingContract" valuePropName="checked">
                    <Checkbox
                      onChange={() =>
                        setIsBalancingContract(!isBalancingContract)
                      }
                    >
                      {t("requests:supllier-contract-balancing", "Contract For Balancing Responsibility")}
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="flex flex-col">
                  <Form.Item
                    name="isMarketAgreement"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        message: t(
                          "requests:supllier-validations-participation-agreement",
                          "You must have check market participation agreement!"
                        ),
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                              new Error(
                                "You must have check market participation agreement!"
                              )
                            ),
                      },
                    ]}
                  >
                    <Checkbox
                      onChange={() => setIsMarketAgreement(!isMarketAgreement)}
                    >
                      {t("requests:supllier-market-participation-agreement", "Market Participation Agreement")}
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="flex flex-col">
                  <Form.Item name="isInformationTrue" valuePropName="checked">
                    <Checkbox
                      onChange={() => setIsInformationTrue(!isInformationTrue)}
                    >
                      {t(
                        "requests:supllier-hereby-certify-information",
                        "I hereby certify that the above information is true and correct."
                      )}
                    </Checkbox>
                  </Form.Item>
                </div>
              </div>
            </div>
          )}
          <div className="section-container flex flex-col md:flex-row justify-center">
            <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
              <h1 className="text-lg font-medium">
                {/* {t("request:upload-document", "Upload Document")} */}
              </h1>
              <div className="flex flex-col">
                <Form.Item
                  name="attachments"
                  className="w-100"
                  htmlFor="attachments"
                  label={parsedType === 1
                    ? t('requests:create.upload-license', "Upload License")
                    : t('requests:create.upload-attachments', "Upload Attachments")
                  }
                  rules={parsedType === 1 ? [
                    {
                      required: true,
                      message: t(
                        "requests:validations.upload-license",
                        "Upload License is required."
                      ),
                    },
                  ] : []}
                >
                  <Upload
                    onChange={handleFileUpload}
                    onRemove={handleFileRemove}
                    beforeUpload={() => false}
                    multiple={true}
                  >
                    <Button
                      className="btn-outlined"
                      size="large"
                    // outlined
                    >
                      <UploadOutlined /> {t("global.upload", "Upload")}
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="section-container flex flex-col md:flex-row w-full mt-4">
            <div className="flex justify-end flex-col md:flex-row w-full">
              <Button
                className="btn-next bg-white mb-3 md:mb-0"
                type="default"
                disabled={isSubmitting}
                size="large"
                htmlType="reset"
              >
                {t("requests:create.cancel-button", "Cancel")}
              </Button>
              <Button
                className="btn-next bg-blue-500 ml-0 md:ml-4"
                type="primary"
                // disabled={!isFormValid()}
                htmlType="submit"
                size="large"
                loading={isSubmitting}
              >
                {t("requests:create.submit-button", "Submit")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CreateRequestsForm;
