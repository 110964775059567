import { Card, Col, Collapse, Row, Spin, Button, Modal } from "antd";
import { PublicApi } from "api/api";
// import { PageHeader } from "components/page-headers/page-headers";
import { CardToolbox, Main, PageHeaderWrapper } from "container/styled";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
// import { Modal } from "components/modals/antd-modals";
// import EquipmentForm, { EquipmentViewMode } from "pages/equipments/components/EquipmentForm";

import FeatherIcon from "feather-icons-react";
import { RegisterDetailsDto } from "api/models/register-details-dto";
import { EquipmentDto } from "api/models/equipment-dto";
import { BranchWithEquipmentsDetailsDto } from "api/models/branch-with-equipments-details-dto";
import "./register-details.css";
import EquipmentForm from "components/register/details/EquipmentForm";
const { Panel } = Collapse;

const Loader = styled.div`
  display: flex;
  height: 400px;
  width: 100%;
  justify-content: center;
  justifyitems: center;
  align-items: center;
`;

const CardKeyValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  span {
    color: #323548;
    font-size: 13px;
    display: block;
    font-weight: bold;
    margin-bottom: 3px;
  }

  p {
    font-weight: 500;
  }
`;

const registersApi = new PublicApi();

export const RegisterDetailsPage = () => {
  const params = useParams();
  const [details, setDetails] = useState<RegisterDetailsDto>({});
  const [loading, setLoading] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentDto>({});
  const [showEquipmentDetailsModal, setShowEquipmentDetailsModal] =
    useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const response = await registersApi.apiPublicRegistersIdGet({
          id: params.registerId as string,
        });
        setDetails(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (params.registerId) fetchDetails();
  }, [params.registerId]);

  const openEquipmentDetailsModal = (equipment: EquipmentDto) => {
    setSelectedEquipment(equipment);
    setShowEquipmentDetailsModal(true);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="register-container">
      {loading ? (
        <Loader>
          <Spin />{" "}
        </Loader>
      ) : (
        <>
          {/* <div className="back-btn-parent">
            <Button type="primary" className="back-btn" onClick={handleGoBack}>
              {t("global:button", "Back")}
            </Button>
          </div> */}
          <CardToolbox>
            <PageHeaderWrapper
              title={`${t(
                "registers:details-of-title",
                "Details of"
              )} ${"details.companyName"}`}
            />
          </CardToolbox>
          <div className="section-container flex justify-center flex-col md:flex-row ">
            <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
              <h1 className="text-lg font-medium">
                {t("request:company-information", "Company Information")}
              </h1>
              <div className="flex flex-col">
                <span className="text-slate-700">
                  {t("requests:create.company-name", "Company name")}
                </span>
                <b>{details.companyName || "-"}</b>
              </div>
              <div className="flex flex-col mt-4">
                <span className="text-slate-700">
                  {t("global:tax-number", "Tax number")}
                </span>
                <b>{details.companyTaxNumber}</b>
              </div>
              {details.isMrvppService && (
                <>
                  <div className="flex flex-col  mt-4">
                    <span className="text-slate-700">
                      {t("requests:details.company-type", "Company Type")}
                    </span>
                    <b>{details.companyType}</b>
                  </div>
                  <div className="flex flex-col  mt-4">
                    <span className="text-slate-700">
                      {t(
                        "requests:details.business-activity",
                        "Business Activity"
                      )}
                    </span>
                    <b>{details.companyActivity}</b>
                  </div>
                  <div className="flex flex-col  mt-4">
                    <span className="text-slate-700">
                      {t("requests:details.license-id", "License Id")}
                    </span>
                    <b>{details.companyLicenseId}</b>
                  </div>
                  <div className="flex flex-col  mt-4">
                    <span className="text-slate-700">
                      {t(
                        "requests:details.license-duration",
                        "License Duration"
                      )}
                    </span>
                    <b>
                      {details.companyLicenseDuration
                        ? moment(details.companyLicenseDuration).format(
                          "MM.DD.yyyy"
                        )
                        : "-"}
                    </b>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="section-container flex justify-center flex-col md:flex-row ">
            <div className="md:w-1/2 w-full mt-4 md:mt-0 bg-white rounded-md p-5 pt-3 shadow-md">
              <h1 className="text-lg font-medium">
                {t("request:company-contact", "Company Contact Information")}
              </h1>
              <div className="flex flex-col  ">
                <span className="text-slate-700">
                  {t("global:contact-person", "Responsible Person Name")}
                </span>
                <b>{details.companyContactPerson}</b>
              </div>
              <div className="flex flex-col  mt-4">
                <span className="text-slate-700">
                  {t(
                    "global:contact-person-email",
                    "Contact Person Email"
                  )}
                </span>
                <b>{details.companyEmail}</b>
              </div>
              <div className="flex flex-col  mt-4">
                <span className="text-slate-700">
                  {t("global:contact-person-phone", "Contact person phone number")}
                </span>
                <b>{details.companyPhoneNumber}</b>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
